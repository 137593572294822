
import { Adress } from './../../../WebApiClient';

export const defaultAdress: Adress = {
  firstName: "",
  lastName: "",
  title: "",
  emailAddress: "",
  phoneNumber: "",
  street: "",
  zip: "",
  city: "",
  country: ""
}

