import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fare from "components/Fare/Fare";
import { FareFilterApplicability } from "components/Fare/FareFilter.Applicability";
import ErrorBoundary from "components/Shared/ErrorBoundary";
import { SetBookingPayload } from "Pages/AvailableFaresPage";
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AvailableFareModel } from "WebApiClient";

interface OwnProps {
  TotalFaresAmount: number;
  Fares: AvailableFaresFare[];
  ShowAllLegsActive: boolean;
  ShowAllFlightTimesActive: boolean;
  ShowAllCalculationActive: boolean;
  FaresWhichPassfilter: number;
  ValidUntil: Date;
  BookFare: (payload: SetBookingPayload) => void,
  ShowCalcDebugger: boolean;
  myRefs?: any
  setShowAllFlightTimesActive: Dispatch<SetStateAction<boolean>>;
}

const AvailalableFaresFares: React.FC<OwnProps> = props => {
  function BookFare(payload: SetBookingPayload) {
    props.BookFare(payload);
  }
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  function ToggleExpiredModal() {
    setExpiredModalOpen(!expiredModalOpen);
  }
  const [fareLegBoolArray, setFareLegBoolArray] = useState<{ fareIndex: number; fareLeg: boolean }[]>([]);

  // useEffect(() => {
  //   if (props.Fares.length) {
  //     props.Fares.map((fare: AvailableFaresFare) => {
  //       setFareLegBoolArray((prevArray: any) => {

  //         const exists = prevArray.some((item: any) => item.fareIndex === fare.Fare.fareIndex);
  //         if (exists) return prevArray;
  //         // Return unchanged if duplicate
  //         return [...prevArray, { fareIndex: fare.Fare.fareIndex, fareLeg: props.ShowAllLegsActive != undefined ? props.ShowAllLegsActive : false }];
  //       });
  //     })
  //   }
  // }, []);


  useEffect(() => {
    if (props.Fares.length) {
      props.Fares.forEach((fare: AvailableFaresFare) => {
        setFareLegBoolArray((prevArray: { fareIndex: number; fareLeg: boolean }[]) => {
          const exists = prevArray.some(item => item.fareIndex === fare.Fare.fareIndex);
          if (exists) {
            return prevArray; // Return unchanged if duplicate
          }
          return [
            ...prevArray,
            {
              fareIndex: fare.Fare.fareIndex,
              fareLeg: props.ShowAllLegsActive ?? false, // Using nullish coalescing operator
            },
          ];
        });
      });
    }
  }, [props.ShowAllLegsActive]);

  useEffect(() => {
    fareLegBoolArray.forEach((fare: { fareIndex: number; fareLeg: boolean }) => {
      fare.fareLeg = props.ShowAllLegsActive;
    });
  }, [props.ShowAllLegsActive]);

  function RenderFares(
    setFareLegBoolArray: (value: any) => void,
    fareLegBoolArray: any[],
    fares: AvailableFaresFare[],
    allLegsActive: boolean,
    flightTimesActive: boolean,
    allCalc: boolean,
    setShowAllFlightTimesActive: Dispatch<SetStateAction<boolean>>,
    myRefs: React.RefObject<any>,
    ShowCalcDebugger: boolean,
    BookFare: (fare: any) => void,
  ): JSX.Element {
    return (
      <React.Fragment>
        {fares.map((fare, index) => (
          <ErrorBoundary key={`frs-${index}`}>
            <Fare
              FareData={fare.Fare}
              ShowAllLegsActive={allLegsActive}
              ShowAllFlightTimesActive={flightTimesActive}
              ShowAllCalculationActive={allCalc}
              Applicability={fare.Applicability}
              key={index}
              isActivate={true}
              myRefs={myRefs}
              IsFareBooking={false}
              BookFare={BookFare}
              ShowCalcDebugger={ShowCalcDebugger}
              TotalFare={fares}
              setFareLegBoolArray={setFareLegBoolArray}
              fareLegBoolArray={fareLegBoolArray}
              isScrollF={"isScrollState"}
              setShowAllFlightTimesActive={setShowAllFlightTimesActive}
            />
          </ErrorBoundary>
        ))}
      </React.Fragment>
    );
  }

  const FilteredFares: JSX.Element = useMemo(
    () =>
      RenderFares(
        setFareLegBoolArray,
        fareLegBoolArray,
        props.Fares,
        props.ShowAllLegsActive,
        props.ShowAllFlightTimesActive,
        props.ShowAllCalculationActive,
        props.setShowAllFlightTimesActive,
        props.myRefs,
        props.ShowCalcDebugger,
        BookFare
      ),
    [
      fareLegBoolArray,
      props.Fares,
      props.ShowAllLegsActive,
      props.ShowAllFlightTimesActive,
      props.ShowAllCalculationActive,
      props.setShowAllFlightTimesActive,
      props.myRefs,
      props.ShowCalcDebugger,
      BookFare,
    ]
  );

  return (
    <React.Fragment>
      <div className="fare__selector_container mt-1 mt-md-3">
        {/* FARES */}
        {props.Fares &&
          <div className="fare__selector">
            {FilteredFares}
            {(props.TotalFaresAmount === 0 || props.FaresWhichPassfilter === 0) &&
              <div id="fare__nofilteredresults" className="border border-dark rounded mx-2 mx-md-0 mb-4 p-2 text-center">
                {props.TotalFaresAmount === 0 &&
                  <p className="mt-2 mb-0 pb-0">Your search returned no results.</p>
                }
                {props.FaresWhichPassfilter === 0 && props.TotalFaresAmount > 0 &&
                  <p className="mt-2 mb-0 pb-0">Please change your filter settings to get more results.</p>
                }
              </div>
            }
          </div>
        }
      </div>
      <Modal centered isOpen={expiredModalOpen} toggle={ToggleExpiredModal}>
        <ModalHeader toggle={ToggleExpiredModal}>Expired</ModalHeader>
        <ModalBody>
          <Alert color="danger">
            <h4 className="alert-heading">This result has expired.</h4>
            <p>Please start a new search.</p>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button onClick={ToggleExpiredModal} color="primary">Ok</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export interface AvailableFaresFare {
  Fare: AvailableFareModel;
  Applicability: FareFilterApplicability
}

export default AvailalableFaresFares;