import { getData as GetCountriesList } from "country-list";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "rootExports/rootReducer";

const CountrySelect: React.FC<AllProps> = props => {

    function GetCountries(): { code: string, name: string }[] {
        let countries = GetCountriesList();
        const preferred = props.PreferredCountry;
        countries = countries.sort(function (a, b) { return (a.name > b.name) ? 1 : -1 });
        const emptyLabel = `- None selected - (${props.Required ? "required" : "optional"})`
        let preferredCountry = countries.find(i => i.code.toUpperCase() === preferred.toUpperCase());
        if (preferredCountry !== undefined) {
            countries = [preferredCountry].concat(countries);
        }
        const empty: { code: string, name: string } = { name: emptyLabel, code: "" };
        countries = [empty].concat(countries);
        return countries;
    }

    function GetValidClass(): string {
        let result = "";
        if (props.Required) {
            result = props.Valid ? "is-valid" : "is-invalid";
        }
        return result;
    }

    return (
        <select disabled={props.disabled} value={props.Value} onChange={(e) => props.Onchange(e.target.value)} className={`form-select ${GetValidClass()}`}>
            {GetCountries().map((e, index) =>
                <option key={`${props.ID}_${index}`} value={e.code}>{e.name}</option>
            )}
        </select>
    );
}


type AllProps = PropsFromState & DispatchProps & OwnProps;

interface OwnProps {
    Value: string;
    ID: string;
    Onchange: (value: string) => void;
    Valid: boolean;
    Required: boolean;
    disabled: boolean

}

interface PropsFromState {
    PreferredCountry: string;

}
const mapStateToProps = (state: State) => ({
    PreferredCountry: state.Session.FrontendSettings.preferredCountry!
});

interface DispatchProps {


}
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({


});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CountrySelect);