import React, { useState } from "react";
import { Alert } from "reactstrap";
import { BookingAddressBehavior } from "WebApiClient";
import { BookOtherValidationResult } from "./FareBooking.Validate.Other";
export interface FareBookingOtherProps {
  WebfarePassword: string;
  WebfarePasswordBehavior: BookingAddressBehavior;
  EmergencyPhone: string;
  contactInfoLanguage: string;
  EmergencyPhoneBehavior: BookingAddressBehavior;
  OnUpdateWebfarePassword: (password: string) => void;
  OnUpdateEmergencyPhone: (password: string) => void;
  OnUpdateContactInfoLanguage: (language: string) => void;
  IsValid: BookOtherValidationResult;
  Icon: JSX.Element;
  IsOpen: boolean;

}

const FareBookingOther: React.FC<FareBookingOtherProps> = ({
  WebfarePassword,
  WebfarePasswordBehavior,
  EmergencyPhone,
  contactInfoLanguage,
  EmergencyPhoneBehavior,
  OnUpdateWebfarePassword,
  OnUpdateEmergencyPhone,
  OnUpdateContactInfoLanguage,
  IsValid,
  Icon,
  IsOpen
}) => {
  const ShowEmergencyPhone = EmergencyPhoneBehavior !== BookingAddressBehavior.UseBranchAdress;
  const ShowWebfarePassword = IsValid.WebfarePasswordRequired;
  const ShowCard = ShowEmergencyPhone || ShowWebfarePassword;

  function GetPasswordClassName(): string {
    let result = "";
    if (IsValid.WebfarePasswordRequired) {
      result = IsValid.WebfarePassword ? "is-valid" : "is-invalid";
    }
    return result;
  }
  const [isCollapsed, setIsCollapsed] = useState(IsOpen);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="card">
      <div className="card-header card-header-primary" onClick={toggleCollapse}>
        {Icon} Required Information
      </div>
      <div
        className={`collapse ${isCollapsed ? "" : "show"}`}
        id="collapseExample2"
      >
        <div className="card-body card-body-primary py-0 py-sm-1 px-md-2 py-lg-3 px-0 px-sm-1 px-md-2 px-lg-3">
          {ShowCard ? (
            <React.Fragment>
              {ShowWebfarePassword && (
                <div className="row align-items-center mb-2 mt-4">
                  <label className="col-12 col-md-4 col-lg-3 col-xl-2">
                    Password{IsValid.WebfarePasswordRequired ? "*" : ""}
                  </label>
                  <div className="col-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      value={WebfarePassword}
                      onChange={(e) => OnUpdateWebfarePassword(e.target.value)}
                      className={`form-control form-control-sm  ${GetPasswordClassName()}`}
                    />
                  </div>
                </div>
              )}
              {ShowEmergencyPhone && (
                <div className="row align-items-center mb-2 mt-4">
                  <label className="col-12 col-md-4 col-lg-3 col-xl-2">
                    Emergency phone*
                  </label>
                  <div className="col-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      value={EmergencyPhone}
                      onChange={(e) => OnUpdateEmergencyPhone(e.target.value)}
                      className={`form-control ${IsValid.EmergencyPhone ? "is-valid" : "is-invalid"
                        }`}
                    />
                  </div>
                </div>
              )}
              {ShowEmergencyPhone && <div className="row align-items-center mb-2 mt-4">
                <label className="col-12 col-md-4 col-lg-3 col-xl-2">
                  Confirmation language
                </label>
                <div className="col-12 col-md-6 col-lg-4">
                  <select
                    className="form-select" style={{ paddingBottom: '5px' }}
                    value={contactInfoLanguage || "EN"}
                    onChange={(e) => OnUpdateContactInfoLanguage(e.target.value)}
                    disabled={false}>
                    <option value="EN">English (EN)</option>
                    <option value="DE">German (DE)</option>
                  </select>
                </div>
              </div>}
            </React.Fragment>
          ) : (
            <div className="row">
              <div className="col-12">
                <Alert color="info">There is no required input.</Alert>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FareBookingOther;
